import { TwoCol, Main, Aside, Box, CoreCompetencies, JobHeader } from "../../../../src/components/felix-mdx";
import * as React from 'react';
export default {
  TwoCol,
  Main,
  Aside,
  Box,
  CoreCompetencies,
  JobHeader,
  React
};