//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/felix-mdx.js
// Description: Components for use w/ Felix's MDX pages.
// Created:     FUB, Oct 12, 2021
//==============================================================================

import React from "react"
import styled from "styled-components"

import { Container, NoContainer, H3, Ul, Li, P } from "./primitives"

//==============================================================================
export const TwoCol = styled(NoContainer)`
    display: flex;
    flex-wrap: wrap;
`

export const Main = styled(Container)`
    flex: 9999 0 max(66%, 300px);
`

export const Aside = styled(Container)`
    flex: 1 0 calc(200px - 2 * ${props => props.theme.spacings.normal});
    border-left: 1px dotted lightgrey;
    font-size: 89%;
`

export const Box = styled(Container)`
    border-radius: 10px;
    border: 1px solid blue;
`

//------------------------------------------------------------------------------
const CoreCompetenciesH3 = styled(H3)`
    text-align: center;
`

const CoreCompetencies2Col = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr;
`

export const CoreCompetencies = ({items}) => {
    const itemsLeft = items.slice(0, (items.length + 1) / 2)
    const itemsRight = items.slice((items.length + 0) / 2)

    return (
        <>
            <CoreCompetenciesH3>Core Competencies</CoreCompetenciesH3>
            <CoreCompetencies2Col>
                <div>
                    <Ul>
                        {itemsLeft.map((item, index) => <Li key={index}>{item}</Li>)}
                    </Ul>
                </div>
                <div>
                    <Ul>
                        {itemsRight.map((item, index) => <Li key={index}>{item}</Li>)}
                    </Ul>
                </div>
            </CoreCompetencies2Col>
        </>
    )
}

/*export const JobHeader = ({ companyName, locationAndPeriod, jobTitle  }) => {
    const Wrapper = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 1.0em;
    `

    return (
        <>
            <Wrapper>
                <H3>{
                    Array.isArray(companyName) ?
                    companyName.map((item, index) => {
                        if (index !== 0) return (<><br/>{item}</>)
                        else return item
                    }) :
                    companyName
                }
            </H3>
                <p>{
                    Array.isArray(locationAndPeriod) ?
                        locationAndPeriod.map((item, index) => {
                            if (index !== 0) return (<><br/>{item}</>)
                            else return item
                        }) :
                        locationAndPeriod
                    }
                </p>
            </Wrapper>
            <p>
                <em>{jobTitle}</em>
            </p>
        </>
    )
}*/

//------------------------------------------------------------------------------
const JobHeaderWrapper = styled.div`
    margin-top: 2.5rem;
`

const JobHeaderCompanyName = styled(P)`
    display: inline;
    font-style: italic;
`

const JobHeaderCompanyLoc = styled(P)`
    display: inline;
`

const JobHeaderJobTitle = styled(H3)`
    display: inline;
    margin-top: 0;
`

const JobHeaderJobPeriod = styled(P)`
    display: inline;
`

export const JobHeader = ({ companyName, companyLocation, jobTitle, jobPeriod }) => {
    return (
        <JobHeaderWrapper>
            <div>
                <JobHeaderCompanyName>{companyName}</JobHeaderCompanyName>, <JobHeaderCompanyLoc>{companyLocation}</JobHeaderCompanyLoc>
            </div>
            <div>
                <JobHeaderJobTitle>{jobTitle}</JobHeaderJobTitle>, <JobHeaderJobPeriod>{jobPeriod}</JobHeaderJobPeriod>
            </div>
        </JobHeaderWrapper>
    )
}

//==============================================================================
// end of file
