//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/uwe-mdx.js
// Description: Components for use w/ Uwe's MDX pages.
// Created:     FUB, Oct 15, 2021
//==============================================================================

import React from "react"

import { A } from "./primitives"

export const LinkToPdf = ({ title, pdf }) => {
    // sample link: https://firebasestorage.googleapis.com/v0/b/bertram-family-com.appspot.com/o/uwe%2F1999_brasilien_nederlands.pdf?alt=media&token=1db0bba9-2c5e-4f26-b467-cf845b029cb1
    const url = `https://firebasestorage.googleapis.com/v0/b/bertram-family-com.appspot.com/o/uwe%2F${pdf}?alt=media`

    return (
        <>
            {"* "}
            <A href={url}>{title}</A>
            <br />
        </>
    )
}

//==============================================================================
// end of file
