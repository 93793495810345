//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/primitives.js
// Description: Styled html primitives.
// Created:     FUB, Oct 12, 2021
//==============================================================================

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { useLocation } from "../utils/location"
import { Lightbox } from "./lightbox"

//==============================================================================
export const Container = styled.div`
    padding: ${props => props.theme.spacings.normal};
`

export const NoContainer = styled.div`
    margin: ${props => props.theme.spacings.negNormal};
`

//------------------------------------------------------------------------------
export const H1 = styled.h1`
`

//export const H2 = styled.h2`
//`

const H2internal = styled.h2`
    color: ${props =>
        (props?.path.startsWith("/felix/") && props.theme.colors.accent.felix[0]) ||
        (props?.path.startsWith("/uwe/") && props.theme.colors.accent.uwe[0]) ||
        (props?.path.startsWith("/birgit/") && props.theme.colors.accent.birgit[0]) ||
        (props?.path.startsWith("/tom/") && props.theme.colors.accent.tom[0]) ||
        (props.theme.colors.accent.felix[0])
    };
`

export const H2 = (props) => {
    // make h2 aware of its page path
    const { path } = useLocation()

    return <H2internal {...props} path={path}/>
}

export const H3 = styled.h3`
`
//&:not(:first-child) {
//    margin-top: 1em;
//}

//------------------------------------------------------------------------------
export const P = styled.p`
`
//&:not(:first-child) {
//    margin-top: 0.5em;
//}

//------------------------------------------------------------------------------
const IntExtLink = ({ href, className, children }) => {
    if (href.endsWith(".png") ||
        href.endsWith(".jpg")
    ) {
        return (
            <Lightbox src={href} children={children} />
        )
    }
    else if (
        href.includes("http://") ||
        href.includes("https://") ||
        href.includes("mailto:") ||
        href.includes("tel:")
    ) {
        return (
            <a
                href={href}
                target="_blank" rel="noopener noreferrer"
                className={className}
            >
                {children}
            </a>
        )
    } else {
        return <Link to={href} className={className}>{children}</Link>
    }
}

export const StyledLink = styled(IntExtLink)`
    color: ${props =>
        (props?.path.startsWith("/felix/") && (props?.accentBackground ? props.theme.colors.accent.felix[1] : props.theme.colors.accent.felix[0])) ||
        (props?.path.startsWith("/uwe/") && (props.accentBackground ? props.theme.colors.accent.uwe[1] : props.theme.colors.accent.uwe[0])) ||
        (props?.path.startsWith("/birgit/") && (props.accentBackground ? props.theme.colors.accent.birgit[1] : props.theme.colors.accent.birgit[0])) ||
        (props?.path.startsWith("/tom/") && (props.accentBackground ? props.theme.colors.accent.tom[1] : props.theme.colors.accent.tom[0])) ||
        (props?.accentBackground ? props.theme.colors.accent.felix[1] : props.theme.colors.accent.felix[0])
    };
    font-weight: bold;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

export const A = ({ href, children, accentBackground }) => {
    // make the link aware of its page path
    const { path } = useLocation()
    return <StyledLink href={href} children={children} accentBackground={accentBackground} path={path} />
}

//------------------------------------------------------------------------------
export const Ul = styled.ul`
`
//     margin-inline-start: 1.5em;

export const Ol = styled.ol`
`

export const Li = styled.li`
`

//------------------------------------------------------------------------------
export const Table = styled.table`
`

export const Tr = styled.tr`
`

export const Td = styled.td`
`

export const Th = styled.th`
`

//==============================================================================
// end of file
