// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-birgit-index-mdx": () => import("./../../../src/pages/birgit/index.mdx" /* webpackChunkName: "component---src-pages-birgit-index-mdx" */),
  "component---src-pages-felix-imprint-index-mdx": () => import("./../../../src/pages/felix/imprint/index.mdx" /* webpackChunkName: "component---src-pages-felix-imprint-index-mdx" */),
  "component---src-pages-felix-index-mdx": () => import("./../../../src/pages/felix/index.mdx" /* webpackChunkName: "component---src-pages-felix-index-mdx" */),
  "component---src-pages-felix-lebenslauf-index-mdx": () => import("./../../../src/pages/felix/lebenslauf/index.mdx" /* webpackChunkName: "component---src-pages-felix-lebenslauf-index-mdx" */),
  "component---src-pages-felix-projects-8051-core-index-mdx": () => import("./../../../src/pages/felix/projects/8051-core/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-8051-core-index-mdx" */),
  "component---src-pages-felix-projects-audiowerk-8-index-mdx": () => import("./../../../src/pages/felix/projects/audiowerk-8/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-audiowerk-8-index-mdx" */),
  "component---src-pages-felix-projects-emi-26-index-mdx": () => import("./../../../src/pages/felix/projects/emi-26/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-emi-26-index-mdx" */),
  "component---src-pages-felix-projects-eng-db-2-index-mdx": () => import("./../../../src/pages/felix/projects/eng-db-2/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-eng-db-2-index-mdx" */),
  "component---src-pages-felix-projects-index-mdx": () => import("./../../../src/pages/felix/projects/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-index-mdx" */),
  "component---src-pages-felix-projects-pad-index-mdx": () => import("./../../../src/pages/felix/projects/pad/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-pad-index-mdx" */),
  "component---src-pages-felix-projects-turingtrader-index-mdx": () => import("./../../../src/pages/felix/projects/turingtrader/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-turingtrader-index-mdx" */),
  "component---src-pages-felix-projects-usb-core-index-mdx": () => import("./../../../src/pages/felix/projects/usb-core/index.mdx" /* webpackChunkName: "component---src-pages-felix-projects-usb-core-index-mdx" */),
  "component---src-pages-felix-resume-index-mdx": () => import("./../../../src/pages/felix/resume/index.mdx" /* webpackChunkName: "component---src-pages-felix-resume-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-tom-index-mdx": () => import("./../../../src/pages/tom/index.mdx" /* webpackChunkName: "component---src-pages-tom-index-mdx" */),
  "component---src-pages-uwe-index-mdx": () => import("./../../../src/pages/uwe/index.mdx" /* webpackChunkName: "component---src-pages-uwe-index-mdx" */)
}

