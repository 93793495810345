//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        utils/location.js
// Description: Retrieve current page location.
// Created:     FUB, Oct 13, 2021
//==============================================================================

import { useEffect, useState } from "react"

// see https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/
export const useLocation = () => {
    const [href, setHref] = useState("")

    useEffect(() => {
        const href0 = window?.location?.href ?? ""
        const href1 = href0 + (href0.endsWith("/") ? "" : "/")
        setHref(href1)
    }, [setHref])

    const protocol = href.substring(0, href.indexOf(":"))

    const domainHelper1 = href.substring(href.indexOf("//") + 2)
    const domain = domainHelper1.substring(0, domainHelper1.indexOf("/"))

    const pathHelper1 = href.substring(href.indexOf("//") + 2)
    const path = pathHelper1.substring(pathHelper1.indexOf("/"))

    return {
        href,
        protocol,
        domain,
        path
    }
}

//==============================================================================
// end of file
