//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/lightbox.js
// Description: Lightbox for high-res images.
// Created:     FUB, Oct 12, 2021
//==============================================================================
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react"
import styled from "styled-components"

//==============================================================================
const DarkBackground = styled.div`
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    alignItems: center;
    justifyContent: left;
    z-index: 999;
    overflow: auto;
`

const ImageWrapper = styled.div`
    flex: none;
    width: 100%;
    min-width: 800px;
    padding: 10px;
`

export const Lightbox = ({ src, alt = "", children }) => {
    const [showDetail, setShowDetail] = useState(false)

    const toggleDetail = () => {
        setShowDetail(showDetail === true ? false : true)
    }

    return (
        <span role="img" onClick={toggleDetail}>
            {children}

            {showDetail === true && (
                <DarkBackground>
                    <ImageWrapper>
                        {<img src={src} alt={alt} />}
                    </ImageWrapper>
                </DarkBackground>
            )}
        </span>
    )
}

 //==============================================================================
 // end of file
